<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<Search
			v-if="search"
			class="mt-10 box"
			:search="search"
			:option="search_option"

			@reset="reset"
			@click="getSearch(1)"
		>
			<button
				slot="last"
				class="bg-identify pa-5-10 vertical-middle mr-10"
				@click="onHoliday"
			>등록</button>
		</Search>

		<div class="mt-10 pa-10 bg-white flex-column overflow-y-auto">

			<div class="justify-space-between">

				<div class="line-height-200">총 <span class="font-weight-bold">{{ search.total_count | makeComma }}</span> 건</div>
                <div class="flex-row">
                    <div v-if="user.roleCode == 0">
                        <button
                            class="box mr-10 pa-4-10 size-px-12 mr-10 bg-white"
                            @click="getSample"
                        >
                            <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                            <span class="vertical-middle">대량 등록 양식</span></button>

                        <label class="box mr-10 pa-4-10 size-px-12 bg-white vertical-middle">
                            <input
                                v-show="false"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                @change="setExcelFile"
                            />
                            <v-icon small class="color-green ">mdi mdi-file-excel</v-icon>
                            대량 등록
                        </label>
                    </div>
                    <select
                        class="pa-5-10 mr-10 size-px-12"
                        v-model="search.size"
                        @change="getSearch(1)"
                    >
                        <option
                            v-for="cnt in codes.list_cnt"
                            :key="'cnt_' + cnt"
                            :value="cnt"
                        >{{ cnt }} 건씩 보기</option>
                    </select>
                </div>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>
                    <col width="150px">
                    <col width="auto">
                    <col width="auto">
                    <col width="200px">
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>공휴일명</th>
					<th>날짜</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in items"
					:key="'list_' + index"
				>
					<td>{{ item.idx}}</td>
					<td>{{ item.title }}</td>
					<td>{{ item.procDate }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="deleteHoliday(item)"
						>삭제</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center top-line-identify mt-10"
			>
				<div class="text-center pa-20">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>
		</div>

        <Pagination
            :program="program"
            :align="'center'"
            :options="search"

            class="mt-auto"
            @click="getSearch"
        ></Pagination>

		<PopupLayer
			v-if="is_holiday"
		>
			<template
				v-slot:body
			>
				<div
					class="bg-white full-height ma-auto flex-column"
					style="width: 480px; min-height: 320px; max-height: 480px;"
				>
					<div class="pa-10-20 justify-space-between under-line">
						<h6>공휴일 등록</h6>
						<button
							@click="is_holiday = false"
						><v-icon>mdi-close-circle</v-icon></button>
					</div>
					<div
						class="full-height pa-20 flex-column justify-center"
					>
						<div class="justify-space-between ">
							<input
								v-model="item.title"
								class="input-box mr-10 text-center  pa-10"
								placeholder="공휴일 명"
								maxlength="10"
							/>
							<input
								v-model="item.date"
								class="input-box mr-10 text-center"
								placeholder="yyyy-mm-dd"
								maxlength="10"
							/>
						</div>

					</div>
					<div
						class="mt-auto pa-10"
					>
						<button
							@click="postHoliday"
							class="btn bg-identify pa-5-10"
						>저장</button>
					</div>
				</div>
			</template>
		</PopupLayer>
        <Modal
            :is_modal="is_upload_bulk"
            :option="{}"
            :top="true"
            :bottom="true"

            title="공휴일 등록"
            width="400px"

            @close="is_upload_bulk = false"
            @click="uploadBulkFile"
            @cancel="is_upload_bulk = false"
        >
            <div
                slot="modal-content"
                class="pa-10 flex-column align-left"
            >
                <div class="text-left">공휴일 등록 시 ".xlsx" 파일만 업로드가 가능합니다. 업로드 시 수분이 걸릴 수 있으니 창을 닫거나 페이지 이동을 하지 마세요.</div>
            </div>
        </Modal>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import PopupLayer from "@/view/Layout/PopupLayer";
import Modal from "@/components/Modal.vue";

export default {
	name: 'Holiday',
	components: {Modal,PopupLayer, Search, Pagination},
	data: function(){
		return {
			program: {
				name: '공휴일 목록'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,keyword: this.$route.query.keyword ? this.$route.query.keyword : ''
                ,total_count: 0
			}
			,search_option: {
				search_type: [
					{ name: '휴일명', column: 1 }
				]
			}
			,items: []
			,item: {
				date: ''
				, title: ''
			}
			, is_holiday: false
			, month: ''
			, day: ''
            , bulkFile: []
            , is_upload_bulk: false
		}
	}
	,computed: {
		date: function(){
			let t = ''
			if(this.month && this.day){
				t = ('0' + this.month).split(-2)
				t += '-'
				t += ('0' + this.day).split(-2)
			}
			return t
		}
	}
	,methods: {
        uploadBulkFile: async function () {
            console.log(this.bulkFile)
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'post'
                    , url: 'holiday/excel'
                    , data: {
                        file: this.bulkFile,
                        isRefresh: false
                    }
                    , multipart: true
                })
                if (result.success) {
                    this.$layout.setNotify( {type: 'success', message: '대량 등록이 완료되었습니다.'})
                } else {
                    this.$layout.setNotify( {type: 'error', message: result.message})
                }
                this.is_upload_bulk = false
            } catch (e) {
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        },
        setExcelFile: function (e) {
            if (e.target.files[0]) {
                this.bulkFile = []
                this.bulkFile = e.target.files[0]
            }
            console.log(this.bulkFile)
            this.is_upload_bulk = true
        },
        getSample: async function () {
            try {
                this.$layout.onLoading()
                const result = await this.$Axios({
                    method: 'get',
                    url: 'holiday/excel',
                    blob: true,
                    sample: true
                })
                if(result.success) {
                    const url = window.URL.createObjectURL(
                        new Blob([result.data.data], { type: 'application/octet-stream'})
                    );

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '공휴일_등록양식.xlsx')

                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                } else {
                    this.$layout.setNotify({type: 'error', message: result.message })
                }
            } catch (e) {
                this.$layout.setNotify({type: 'error', message: this.$language.common.error})
                console.log(e)
            } finally {
                this.$layout.offLoading()
            }
        },
		getData: async function(){
			try{
				this.$layout.onLoading()
				this.$set(this.search, 'keywordType', this.search.search_type)
				let result = await this.$Axios({
					method: 'get'
					, url: 'holiday'
					, data: this.search
				})

				if(result.success){
					this.items = result.data.data
					this.search.total_count = result.data.totalCount
					localStorage.setItem('query', JSON.stringify(this.search))
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			this.getData()
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,search_type: ''
				,keyword: ''
			}
			this.getData()
		}
		, onHoliday: function(){
			this.is_holiday = true
		}
		, postHoliday: async function(){
			try{
				this.$layout.onLoading()
				this.$set(this.search, 'keywordType', this.search.search_type)
				let result = await this.$Axios({
					method: 'post'
					, url: 'holiday'
					, data: {
						idx: this.item.idx
						, date: this.item.date
						, title: this.item.title
					}
				})

				if(result.success){
					this.is_holiday = false
					await this.getData()
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		, deleteHoliday: async function(item){
			try{
				this.$layout.onLoading()
				this.$set(this.search, 'keywordType', this.search.search_type)
				let result = await this.$Axios({
					method: 'delete'
					, url: 'holiday/_' + item.idx
					, data: {
					}
				})

				if(result.success){
					this.is_holiday = false
					await this.getData()
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
	,watch: {

	}
}
</script>